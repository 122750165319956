import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EventDetailComponent({ values }) {
  const { t, i18n } = useTranslation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="standard-blog-item mb-50">
              <div className="standard-blog-thumb">
                <img
                  style={{ width: "100%" }}
                  src={values.image_url || "/img/blog/blog_thumb01.jpg"}
                  alt=""
                />
              </div>
              <div className="standard-blog-content blog-details-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags">
                      <i className="flaticon-bookmark-1" />
                      <a href="/#">{t("siberian_husky")} </a>
                      <a href="/#">{t("breed")}</a>
                    </li>
                    <li>
                      <i className="far fa-user" />
                      <a href="/#">{t("admin")}</a>
                    </li>
                    <li>
                      <i className="far fa-bell" />{" "}
                      {formatDate(values.createdAt)}
                    </li>
                  </ul>
                </div>
                <h4 className="title">{values.fullname}</h4>
                <p>{values.desc}</p>
                <div
                  className="blog-line"
                  data-background="img/blog/blog_item_line.png"
                />
                <div className="blog-details-bottom">
                  <div className="blog-details-tags">
                    <ul>
                      <li className="title">
                        <i className="fas fa-tags" /> {t("tags")} :
                      </li>
                      <li>
                        <a href="/#">{t("business")}</a>
                      </li>
                      <li>
                        <a href="/#">{t("work")}</a>
                      </li>
                      <li>
                        <a href="/#">{t("knowledge")}</a>
                      </li>
                      <li>
                        <a href="/#">{t("online")}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="blog-details-social">
                    <ul>
                      <li>
                        <a href="/#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="comment-reply-box">
              <h5 className="title">{t("leave_reply")}</h5>
              <form className="comment-reply-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text" placeholder={t("Author")} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="email" placeholder={t("your_email")} />
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <textarea
                    name="message"
                    placeholder={t("Type_Comment_Here")}
                    defaultValue={""}
                  />
                </div>
                <div className="form-grp checkbox-grp">
                  <input type="checkbox" id="checkbox" />
                  <label htmlFor="checkbox">{t("show_email")}</label>
                </div>
                <button type="submit" className="btn">
                  {t("submit_now")}
                </button>
              </form>
            </div> */}
          </div>
          <div className="col-lg-4">
            <aside className="blog-sidebar">
              {/* <div className="widget">
                <h4 className="sidebar-title">{t("search")}</h4>
                <div className="sidebar-search">
                  <form>
                    <input type="text" placeholder={t("search")} />
                    <button type="submit">
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div> */}
              {/* <div className="widget">
                <h4 className="sidebar-title">{t("categories")}</h4>
                <div className="sidebar-cat-list">
                  <ul>
                    <li>
                      <a href="/#">
                        {t("siberian_husky")}{" "}
                        <i className="fas fa-angle-double-right" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        {t("german_sherped")}{" "}
                        <i className="fas fa-angle-double-right" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        {t("french_bulldog")}{" "}
                        <i className="fas fa-angle-double-right" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        {t("golden_retriever")}{" "}
                        <i className="fas fa-angle-double-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="widget">
                <h4 className="sidebar-title">{t("recent_post")}</h4>
                <div className="rc-post-list">
                  <ul>
                    <li>
                      <div className="rc-post-thumb">
                        <Link to="/blog-details">
                          <img src="/img/blog/rc_post_thumb01.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="rc-post-content">
                        <h5 className="title">
                          <Link to="/blog-details">{t("your_pet_needs")}</Link>
                        </h5>
                        <div className="rc-post-meta">
                          <ul>
                            <li>
                              <i className="far fa-calendar-alt" />
                              {t("april_15_2021")}
                            </li>
                            <li>
                              {t("by")} <a href="/#">{t("admin")}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="rc-post-thumb">
                        <Link to="/blog-details">
                          <img src="/img/blog/rc_post_thumb02.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="rc-post-content">
                        <h5 className="title">
                          <Link to="blog-details">
                            {t("pets_need_special_food")}
                          </Link>
                        </h5>
                        <div className="rc-post-meta">
                          <ul>
                            <li>
                              <i className="far fa-calendar-alt" />{" "}
                              {t("october_15_2021")}
                            </li>
                            <li>
                              {t("by")} <a href="/#">{t("admin")}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="widget sidebar-newsletter">
                <div className="sn-icon">
                  <img src="/img/icon/sn_icon.png" alt="" />
                </div>
                <div className="sn-title">
                  <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                  <p>{t("sign_up_for_latest_news")}</p>
                </div>
                <form className="sn-form">
                  <input type="text" placeholder={t("Enter_Your_Email")} />
                  <button className="btn">{t("newsletter_button")}</button>
                </form>
              </div> */}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EventDetailComponent;
