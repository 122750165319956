import React from "react";
import BreadCumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const KVKK = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BreadCumb path={t("f_kvkk")} />
      <div className="container my-5">
        <h3 style={{ color: "black" }}>
          6698 SAYILI KANUN KAPSAMINDA KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN
          AYDINLATMA METNİ
        </h3>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          İşbu aydınlatma metni, Elitsoft Bilişim Sistemleri Anonim Şirketi
          (Vergi No: 3321359124) tarafından 6698 sayılı Kişisel Verilerin
          Korunması Kanunu (KVKK) ve ilgili diğer yasal mevzuat hükümleri
          kapsamında kişisel verilerin işlenmesine ilişkin olarak aydınlatılma
          amacıyla hazırlanmıştır.
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            1. TANIMLAR
          </div>
          {`- Kişisel Veri: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,
- Özel Nitelikli Kişisel Veri: Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi
inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da
sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili
verileri ile biyometrik ve genetik verileri,
- Açık Rıza: Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle
açıklanan rızayı,
- Anonim Hâle Getirme: Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir
surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle
getirilmesini,
- Kişisel Verilerin İşlenmesi: Kişisel verilerin tamamen veya kısmen otomatik olan ya
da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla
elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden
düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi,
sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde
gerçekleştirilen her türlü işlemi,
- Veri İşleyen: Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri
işleyen gerçek veya tüzel kişiyi,
- Veri Kayıt Sistemi: Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği
kayıt sistemini,
- Veri Sorumlusu: Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri
kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel
kişiyi,
- Kişisel Verilerin Silinmesi: KVKK ve ilgili diğer kanun hükümlerine uygun olarak
işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
kişisel verilerin resen veya ilgili kişinin talebi üzerine veri sorumlusu tarafından ilgili
kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi
işlemini,
- Kişisel Verilerin Yok Edilmesi: KVKK ve ilgili diğer kanun hükümlerine uygun
olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması
hâlinde kişisel verilerin resen veya ilgili kişinin talebi üzerine veri sorumlusu
tarafından hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar
kullanılamaz hale getirilmesi işlemini ifade eder.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            2. KİŞİSEL VERİLERİNİZİN İŞLENMESİNE İLİŞKİN GENEL ESASLAR NELERDİR?
          </div>
          {`Kişisel verileriniz;
- Hukuka ve dürüstlük kurallarına uygun olma,
- Doğru ve gerektiğinde güncel olma,
- Belirli, açık ve meşru amaçlar için işlenme,
- İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma,
- İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar
muhafaza edilme ilkelerine ve ancak KVKK ve diğer kanunlarda öngörülen usul ve
esaslara uygun olarak işlenmektedir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            3. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ, KANUNİ İŞLENME VE
            SAKLANMA ŞARTLARI NELERDİR?
          </div>
          {`Kişisel verilerinizi, https://petapps.net/ internet sitesinde yer alan kayıt formu ve tarafımıza
fiziki olarak iletmiş olduğunuz bilgi ve belgeler vasıtasıyla toplamaktayız.
Kişisel veriler ilgili kişinin açık rızası olmaksızın işlenemez ve saklanamaz.
Aşağıdaki şartlardan birinin varlığı hâlinde, ilgili kişinin açık rızası aranmaksızın kişisel
verilerinin işlenmesi ve saklanması mümkündür:
- Kanunlarda açıkça öngörülmesi.
- Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki
geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
korunması için zorunlu olması.
- Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.
- Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.
- İlgili kişinin kendisi tarafından alenileştirilmiş olması.
- Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.
- İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun
meşru menfaatleri için veri işlenmesinin zorunlu olması.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            4. KİŞİSEL VERİLERİNİZİ KİMLERE VE HANGİ AMAÇLA AKTARIYORUZ?
          </div>
          {`Ad, soyad, telefon numarası, anlık konum bilgisi ve benzeri kişisel verileriniz, gerektiği
takdirde ve gerekli olduğu kadarıyla evcil hayvan gezdirme ve evcil hayvanların bakımı
faaliyetleri sebebi ile eşleştiğiniz kimselerle, faaliyetlerimiz gereği anlaşmalı olduğumuz ve
hizmet sunduğumuz kişi ve kurumlarla, avukatlar, denetim şirketleri veya yasal bir zorunluluk
gereği bu verileri talep etmeye yetkili olan kamu kurum veya kuruluşları ile paylaşabilecektir.
Kişisel verileriniz; mevzuattan doğan yükümlülüklerin yerine getirilmesi, faaliyetlerin
mevzuata uygun yürütülmesi, iletişim faaliyetlerinin yürütülmesi, insan kaynakları
süreçlerinin planlanması, iş faaliyetlerinin yürütülmesi, denetimi, iş sağlığı, güvenliği
faaliyetlerinin yürütülmesi, iş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve
değerlendirilmesi, iş sürekliliğinin sağlanması faaliyetlerinin yürütülmesi amaçları ve
aydınlatma metninde belirtilen amaç ve şartlara uygun olarak ve gerektiğinde KVKK’nın 8.
ve 9. maddelerinde öngörülen şartlar dahilinde aktarılacaktır.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            5. KİŞİSEL VERİLERİNİZİN SİLİNMESİ, YOK EDİLMESİ VEYA ANONİM HALE
            GETİRİLMESİ ŞARTLARI NEDİR?
          </div>
          {`Yönetmelik uyarınca, aşağıda sayılan hallerde veri sahiplerine ait kişisel veriler, tarafımızca
re’sen, yahut talep üzerine silinir, yok edilir veya anonim hale getirilir:
Kişisel verilerin işlenmesine veya saklanmasına esas teşkil eden ilgili mevzuat hükümlerinin
değiştirilmesi veya ilgası sebebiyle gerekli olması hali,
Kişisel verilerin işlenmesini veya saklanmasını gerektiren amacın ortadan kalkması,
Kanun’un 5. ve 6. maddelerindeki kişisel verilerin işlenmesini gerektiren şartların ortadan
kalkması,
Kişisel verileri işlemenin sadece açık rıza şartına istinaden gerçekleştiği hallerde, ilgili kişinin
rızasını geri alması,
İlgili kişinin, Kanun’un 11. maddesindeki hakları çerçevesinde kişisel verilerinin silinmesi,
yok edilmesi veya anonim hale getirilmesine ilişkin yaptığı başvurunun veri sorumlusu
tarafından kabul edilmesi,
Veri sorumlusunun, ilgili kişi tarafından kişisel verilerinin silinmesi, yok edilmesi veya
anonim hale getirilmesi talebi ile kendisine yapılan başvuruyu reddetmesi, verdiği cevabın
yetersiz bulunması veya Kanun’da öngörülen süre içinde cevap vermemesi hallerinde;
Kurul’a şikâyette bulunulması ve bu talebin Kurul tarafından uygun bulunması,
Kişisel verilerin saklanmasını gerektiren azami sürenin geçmiş olmasına rağmen, kişisel
verileri daha uzun süre saklamayı haklı kılacak herhangi bir şartın mevcut olmaması.
Saklama ve İmha Süreleri
Kanun ve diğer ilgili mevzuat hükümlerine uygun olarak elde edilen kişisel verilerinizin
saklama ve imha sürelerinin tespitinde aşağıda sırasıyla belirtilen hususlara göre hareket
edilir:
Kişisel verilerin saklanmasına ilişkin süre, KVKK ve ilgili diğer mevzuat hükümleri
çerçevesinde öngörülmüşse, bu süreye uyularak anılan sürenin sona ermesini takiben ilgili
veri imha edilir.
Kişisel verilerin saklanmasına ilişkin süre, KVKK ve ilgili diğer mevzuat hükümleri
çerçevesinde öngörülmemişse; KVKK madde 6 uyarınca kişisel veriler ve özel nitelikli kişisel
veriler ayrımı yapılarak özel nitelikli tüm kişisel veriler imha edilir. Söz konusu verilerin
imhası, verilerin saklanması yöntemi ve verinin niteliği göz önüne alınarak belirlenir.
Verinin saklanmasının Kanun’un 4. maddesinde belirtilen ilkelere uygunluğu örneğin; verinin
saklanmasında Şirket’in meşru bir amacının olup olmadığı sorgulanır. Saklanmasının
Kanun’un 4. maddesinde yer alan ilkelere aykırılık teşkil edebileceği tespit edilen veriler
silinir, yok edilir ya da anonim hale getirilir.
Verinin saklanmasının Kanun’un 5. ve 6. maddelerinde öngörülmüş olan istisnalardan
hangisi/hangileri kapsamında değerlendirilebileceği tespit edilir. Tespit edilen istisnalar
çerçevesinde verilerin saklanması gereken makul süreler tespit edilir. Söz konusu sürelerin
sona ermesi halinde veriler silinir, yok edilir ya da anonim hale getirilir.
Saklama süresi dolan kişisel veriler, 6 (altı) aylık periyodlarla işbu Aydınlatma Metni’nde yer
verilen usullere uygun olarak anonim hale getirilir veya imha edilir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            6. VERİ SORUMLUSUNUN AYDINLATMA YÜKÜMLÜLÜĞÜ VE İLGİLİ KİŞİNİN
            HAKLARI NELERDİR?
          </div>
          {`Kişisel verilerin elde edilmesi sırasında veri sorumlusu veya yetkilendirdiği kişi, ilgili
kişilere; veri sorumlusunun ve varsa temsilcisinin kimliği, kişisel verilerin hangi amaçla
işleneceği, işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, kişisel veri
toplamanın yöntemi ve hukuki sebebi, 11 inci maddede sayılan diğer hakları konusunda bilgi
vermekle yükümlüdür.
KVKK madde 11 uyarınca; herkes, veri sorumlusuna başvurarak kendisiyle ilgili;
a) Kişisel veri işlenip işlenmediğini öğrenme,
b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
öğrenme,
ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
e) 7 nci maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok
edilmesini isteme,
f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
bildirilmesini isteme,
g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın
giderilmesini talep etme haklarına sahiptir.
İlgili kişi yukarıda sıralanan haklarına ilişkin taleplerini, ilgili veri sahibinin kimliğini tespit
edecek belgelerle birlikte ve yazılı olarak ıslak imzalı dilekçesini elden, noter aracılığı ile
veya iadeli taahhütlü mektupla Şirket adresine ileterek, Şirket resmi mail adresine elektronik
posta yöntemi ile ileterek ya da KVKK tarafından öngörülen bir yöntemi izlemek suretiyle
iletebilir. Talepler, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz
olarak sonuçlandırır. KVKK kapsamında yazılı hallerde ücret talep etme hakkı saklıdır.`}
        </div>
      </div>
    </div>
  );
};

export default KVKK;
