import React, { useState } from "react";
import { Box, Typography, Paper, Modal, Button } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const AdoptOffersList = ({ values }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const token = JSON.parse(localStorage.getItem("pet_token"));

  const handleOpen = (offer) => {
    setSelectedOffer(offer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOffer(null);
  };

  const handleOfferAccept = async () => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.put(
        `${API_URL}adopt/offer/result/${selectedOffer._id}`,
        {
          isAccept: true,
          adopt: values.offers[0].adopt,
        },
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text: "Teklifi kabul ettiniz.",
        }).then(() => {
          setTimeout(() => {
            window.location.reload();
            window.ReactNativeWebView.postMessage("reload");
          }, 100);
        });
        handleClose();
      }
    } catch (error) {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t(error.response.data.error),
      });
      console.error("Error fetching offers:", error);
    }
  };

  const handleOfferCancel = async (offer) => {
    const result = await Swal.fire({
      title: "Emin misiniz?",
      text: "Bu teklifi iptal etmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    });

    if (result.isConfirmed) {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.put(
          `${API_URL}adopt/offer/result/${offer._id}`,
          {
            isAccept: false,
          },
          {
            headers: {
              "petapp-auth-token": token,
            },
          }
        );
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Başarılı!",
            text: "Teklifi iptal ettiniz.",
          }).then(() => {
            window.location.reload();
          });
          handleClose();
        }
      } catch (error) {
        handleClose();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t(error.response.data.error),
        });
        console.error("Error fetching offers:", error);
      }
    }
  };

  return (
    <div className="shop-area pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="shop-wrap">
              <div className="row justify-content-center">
                {values.offers &&
                  values.offers.map((value, index) => (
                    <Box
                      key={index}
                      sx={{
                        margin: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        maxHeight: 400,
                        gap: { xs: "15px", sm: "20px", md: "30px" },
                      }}
                    >
                      <Paper
                        sx={{
                          padding: 2,
                          marginBottom: 1,
                          flex: "1 1 calc(33% - 20px)",
                          boxSizing: "border-box",
                          minWidth: "250px",
                        }}
                      >
                        <div className="shop-thumb">
                          <Link to={`/app/user/${value.user._id}`}>
                            <img
                              src={
                                values.user?.image
                                  ? values.user.image
                                  : "/img/unuser.png"
                              }
                              alt=""
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Link>
                        </div>
                        <h4 className="title">{value.user.fullname}</h4>
                        {value.isAccept && (
                          <div>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "20px", color: "black" }}
                            >
                              İletişim Bilgileri:
                            </Typography>
                            <Typography variant="body1">
                              <i className="fas fa-phone"></i>{" "}
                              {value.user.phone}
                            </Typography>
                            <Typography variant="body1">
                              <i className="fas fa-envelope"></i>{" "}
                              {value.user.email}
                            </Typography>
                          </div>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "15px",
                            paddingTop: "10px",
                          }}
                        >
                          <Typography
                            sx={{ cursor: "pointer" }}
                            variant="body1"
                            onClick={() =>
                              value.isAccept
                                ? handleOfferCancel(value)
                                : handleOpen(value)
                            }
                          >
                            {value.isAccept ? t("cancel") : t("accept")}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%", xl: "30%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div onClick={handleClose} className="modalClose">
            <img
              src="/img/close.png"
              alt="close_button"
              width={40}
              height={40}
            />
          </div>
          {selectedOffer && (
            <>
              <Typography variant="h6" component="h2">
                Teklif Detayları
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <b>Teklif veren ismi:</b> {selectedOffer.user.fullname}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <b>Mesaj:</b> {selectedOffer.message}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <button className="btn" onClick={handleOfferAccept}>
                  {t("accept")}
                </button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AdoptOffersList;
