import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Box, Typography, Button } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import PetsIcon from "@mui/icons-material/Pets";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import AddAdoptModal from "../../../AdoptModal/AddAdopt";
import Swal from "sweetalert2";
import { getBreeds } from "../../../../store/slices/breedSlice";
import axios from "axios";

function PetsComponent({ pets, allBreeds }) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const breeds = useSelector((state) => state.breed.breeds);
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [birthday, setBirthday] = useState("");
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [fileName, setFileName] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [addModal, setAddModal] = useState(false);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedPet(null);
  };

  const handleEditCloseModal = () => {
    setEditModalVisible(false);
    setSelectedPet(null);
    setBirthday(null);
    setBase64Image(null);
  };

  const handleEdit = (pet) => {
    setSelectedPet(pet);
    setBirthday(pet.birthday);
    setBase64Image(pet.pet_image);
    setEditModalVisible(true);
  };

  useEffect(() => {
    if (editModalVisible && selectedPet) {
      dispatch(getBreeds(selectedPet.type));
    }
  }, [editModalVisible, selectedPet, dispatch]);

  const handleDelete = (petId) => {
    Swal.fire({
      icon: "warning",
      text: "Do you want to delete pet?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const deletePet = async () => {
          try {
            const API_URL =
              process.env.REACT_APP_NODE_ENV === "production"
                ? process.env.REACT_APP_PRODUCTION_API_URL
                : process.env.REACT_APP_LOCAL_API_URL;

            const res = await axios.delete(`${API_URL}pets/${petId}`, {
              headers: {
                "petapp-auth-token": token,
              },
            });

            if (res.data) {
              setModalVisible(false);
              Swal.fire({
                icon: "success",
                title: "Success",
                text: t("Your pet has been successfully deleted"),
              }).then((result) => {
                if (result.isConfirmed) {
                  setTimeout(() => {
                    window.location.reload();
                    window.ReactNativeWebView.postMessage("reload");
                  }, 100);
                }
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text:
                error.response?.data?.errors?.[0]?.message ||
                "An error occurred",
            });
            setModalVisible(false);
          }
        };
        deletePet();
      } else if (result.isDenied) {
        console.log(2);
      }
    });
  };

  const handleAddPet = () => {
    setModalVisible(true);
  };

  const handleAddPetForm = async (e) => {
    e.preventDefault();

    const payload = {
      fullname: e.target.fullname.value,
      type: e.target.type.value,
      pet_image: base64Image,
      breed: e.target.breed.value,
      size: e.target.size.value,
      gender: e.target.gender.value,
      color: e.target.color.value,
      birthday: birthday,
      desc: e.target.desc.value,
      medicine: e.target.medicine.value,
      allergy: e.target.allergy.value,
      special_case: e.target.case.value,
    };

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.post(`${API_URL}pets`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });

      if (res.data) {
        setModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your pet has been successfully added"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
      setModalVisible(false);
    }
  };

  const handleEditPetForm = async (e) => {
    e.preventDefault();

    const payload = {
      fullname: e.target.fullname_edit.value,
      type: e.target.type_edit.value,
      breed: e.target.breed_edit.value,
      pet_image: base64Image?.includes("base64") ? base64Image : null,
      size: e.target.size_edit.value,
      gender: e.target.gender_edit.value,
      color: e.target.color_edit.value,
      birthday: birthday,
      desc: e.target.desc_edit.value,
      medicine: e.target.edit_medicine.value,
      allergy: e.target.edit_allergy.value,
      special_case: e.target.edit_case.value,
    };

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.put(
        `${API_URL}pets/${selectedPet._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );

      if (res.data) {
        setEditModalVisible(false);
        setModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your pet has been successfully edited"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
      setEditModalVisible(false);
    }
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    setFileName(`${selectedFile.name} - Seçildi`);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <section className="adoption-shop-area" style={{ paddingTop: "60px" }}>
      <div ref={dropdownRef} className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div style={{ margin: "10px", position: "relative" }}>
              <img
                onClick={() => {
                  handleAddPet();
                }}
                src="/img/plus.png"
                alt=""
                style={{ cursor: "pointer" }}
              />
              <div className="adoption-shop-content">
                <h4 className="title">{t("new_pet")}</h4>
                <div className="adoption-meta">
                  <ul className="d-flex flex-column align-items-start">
                    <li>
                      <i className="fas fa-dog" />
                      {t("dog_cat_bird")}...
                    </li>
                    <li>{t("animals_description")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {pets.map((pet, index) => (
            <div key={pet._id} className="col-lg-4 col-md-6">
              <div style={{ margin: "10px", position: "relative" }}>
                <Link to={`/app/user_pets/${pet._id}`}>
                  {pet.pet_image ? (
                    <img
                      src={pet.pet_image}
                      style={{ borderRadius: "4px" }}
                      alt="Pet"
                    />
                  ) : pet.type === "Dog" ? (
                    <img
                      src="/img/pet/dog.png"
                      style={{ borderRadius: "4px" }}
                      alt="Dog"
                    />
                  ) : pet.type === "Cat" ? (
                    <img
                      src="/img/pet/cat.png"
                      style={{ borderRadius: "4px" }}
                      alt="Cat"
                    />
                  ) : pet.type === "Bird" ? (
                    <img
                      src="/img/pet/bird.png"
                      style={{ borderRadius: "4px" }}
                      alt="Bird"
                    />
                  ) : null}
                </Link>
                {pet.adopt?.offers?.length > 0 && (
                  <Link to={`/app/adopt_offers/${pet._id}`}>
                    <div className="no-pets-offer">
                      <i className="fas fa-user" /> {pet.adopt.offers.length}{" "}
                      {t("offer")}
                    </div>
                  </Link>
                )}
                <div className="adoption-shop-content">
                  <h4 className="title">
                    <Link to={`/app/user_pets/${pet._id}`}>{pet.fullname}</Link>
                  </h4>
                  <div className="adoption-meta">
                    <ul>
                      <li>
                        <i className="fas fa-paw" />
                        {pet.breed}
                      </li>
                      <li>
                        <i className="fas fa-birthday-cake"></i>{" "}
                        {new Date(pet.birthday).toLocaleDateString("tr-TR", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    width: "42px",
                    height: "42px",
                    backgroundColor: "#f04336",
                    color: "white",
                    padding: "0 14px",
                    borderRadius: "50%",
                    lineHeight: "42px",
                  }}
                >
                  <i
                    className="fa fa-bars"
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() => handleDropdownToggle(index)}
                  ></i>
                  {dropdownIndex === index && (
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "0px",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        padding: "5px",
                      }}
                    >
                      <ul>
                        <li>
                          <Button
                            style={{ color: "blue" }}
                            onClick={() => {
                              handleEdit(pet);
                            }}
                            className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                          >
                            <Edit
                              style={{ color: "blue", marginRight: "3px" }}
                            />
                            {t("edit")}
                          </Button>
                        </li>
                        {/* <li>
                          <Button
                            style={{ color: "blue" }}
                            onClick={() => {
                              setSelectedPet(pet);
                              setAddModal(true);
                            }}
                            className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                          >
                            <PetsIcon
                              style={{
                                color: "blue",
                                marginRight: "3px",
                                fontSize: "20px",
                              }}
                            />
                            {t("adoptt")}
                          </Button>
                        </li> */}
                        <li>
                          <Button
                            style={{ color: "red" }}
                            onClick={() => {
                              handleDelete(pet._id);
                            }}
                            className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                          >
                            <Delete
                              style={{ color: "red", marginRight: "3px" }}
                            />
                            {t("delete")}
                          </Button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Modal
            open={modalVisible}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="col-md-10 col-10" sx={boxStyle}>
              <div onClick={handleCloseModal} className="modalClose">
                <img
                  src="/img/close.png"
                  alt="close_button"
                  width={40}
                  height={40}
                />
              </div>
              <form
                className="contact-form"
                onSubmit={handleAddPetForm}
                style={{
                  overflowY: "scroll",
                  height: "-webkit-fill-available",
                }}
              >
                <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                  <div>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("pets_Image")}
                    </Typography>

                    <label htmlFor="fileInput">
                      <div
                        className="d-flex justify-content-center align-items-center mb-4"
                        style={{
                          position: "relative",
                          backgroundColor: "#637579",
                          width: "200px",
                          height: "200px",
                          cursor: "pointer",
                        }}
                      >
                        {base64Image ? (
                          <img
                            src={base64Image}
                            alt="Selected"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <i
                            style={{ color: "white" }}
                            className="fas fa-camera fa-2x"
                          ></i>
                        )}

                        {base64Image && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              left: "-15px",
                              backgroundColor: "#637579",
                              borderRadius: "50%",
                              width: 40,
                              height: 40,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fas fa-camera"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("pet_name")}
                    </Typography>
                    <input required id="fullname" type="text" />
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                    <div className="d-flex col-md-5 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("pet")}
                      </Typography>
                      <select
                        required
                        style={{
                          padding: "8px",
                          backgroundColor: "#637579",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="type"
                        className="form-select btn"
                        onChange={(e) => {
                          dispatch(getBreeds(e.target.value));
                        }}
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          style={{ backgroundColor: "#0a313b" }}
                          value=""
                          disabled
                        >
                          {t("select")}
                        </option>
                        {allBreeds &&
                          allBreeds.map((allBreed, index) => (
                            <option
                              key={index}
                              value={allBreed}
                              style={{ backgroundColor: "#0a313b" }}
                            >
                              {t(allBreed)}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="d-flex col-md-5 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("breed")}
                      </Typography>
                      <select
                        required
                        style={{
                          backgroundColor: "#637579",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="breed"
                        className="form-select btn"
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          style={{ backgroundColor: "#0a313b" }}
                          value={""}
                          disabled
                        >
                          {t("select")}
                        </option>
                        {breeds &&
                          breeds.map((dog) => (
                            <option
                              style={{ backgroundColor: "#0a313b" }}
                              key={dog}
                              defaultValue={dog}
                            >
                              {dog}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                    <div className="d-flex col-12 col-md-3 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("size")}{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {t("lbs")}
                        </span>
                      </Typography>
                      <input required id="size" type="number" step={"0.1"} />
                    </div>
                    <div className="d-flex col-12 col-md-4 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("gender")}
                      </Typography>
                      <select
                        required
                        style={{
                          backgroundColor: "#637579",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="gender"
                        className="form-select btn"
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          disabled
                          value=""
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("select")}
                        </option>
                        <option
                          defaultValue={"male"}
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("male")}
                        </option>
                        <option
                          defaultValue={"female"}
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("female")}
                        </option>
                      </select>
                    </div>
                    <div className="d-flex col-12 col-md-3 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("color")}
                      </Typography>
                      <input required id="color" type="text" />
                    </div>
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("birthday")}
                    </Typography>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      required
                      wrapperClassName="w-100"
                      selected={birthday}
                      onChange={(date) => {
                        setBirthday(date);
                      }}
                    />
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("pet_introduction")}
                    </Typography>
                    <textarea
                      name="desc"
                      id="desc"
                      style={{
                        resize: "none",
                        height: "200px",
                      }}
                    ></textarea>
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100 mt-2">
                    <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {t("medicines")}
                      </Typography>
                      <textarea
                        name="medicine"
                        id="medicine"
                        style={{
                          resize: "none",
                          height: "200px",
                        }}
                      ></textarea>
                    </div>
                    <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {t("allergy")}
                      </Typography>
                      <textarea
                        name="allergy"
                        id="allergy"
                        style={{
                          resize: "none",
                          height: "200px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {t("special_cases")}
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {t("max_char")}
                      </span>
                    </Typography>
                    <textarea
                      name="case"
                      id="case"
                      style={{
                        resize: "none",
                        height: "200px",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    style={{ width: "80%", justifyContent: "center" }}
                    className="btn"
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            </Box>
          </Modal>
          <AddAdoptModal
            open={addModal}
            handleClose={() => setAddModal(false)}
            selectedPet={selectedPet}
          />
        </div>
        {selectedPet && breeds && (
          <div>
            <div>
              <Modal
                open={editModalVisible}
                onClose={handleEditCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="col-md-10 col-10" sx={boxStyle}>
                  <div onClick={handleEditCloseModal} className="modalClose">
                    <img
                      src="/img/close.png"
                      alt="close_button"
                      width={40}
                      height={40}
                    />
                  </div>
                  <form
                    className="contact-form"
                    onSubmit={handleEditPetForm}
                    style={{
                      overflowY: "scroll",
                      height: "-webkit-fill-available",
                    }}
                  >
                    <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                      <div>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {t("pets_Image")}
                        </Typography>
                        <label htmlFor="fileInput">
                          <div
                            className="d-flex justify-content-center align-items-center mb-4"
                            style={{
                              position: "relative",
                              backgroundColor: "#637579",
                              width: "200px",
                              height: "200px",
                              cursor: "pointer",
                            }}
                          >
                            {base64Image ? (
                              <img
                                src={base64Image}
                                alt="Selected"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <i
                                style={{ color: "white" }}
                                className="fas fa-camera fa-2x"
                              ></i>
                            )}

                            {base64Image && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-5px",
                                  left: "-15px",
                                  backgroundColor: "#637579",
                                  borderRadius: "50%",
                                  width: 40,
                                  height: 40,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fas fa-camera"
                                  style={{ color: "white" }}
                                ></i>
                              </div>
                            )}
                          </div>
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileSelect}
                        />
                      </div>
                      <div className="col-md-10 col">
                        <Typography sx={{ fontWeight: "bold", color: "black" }}>
                          {t("pet_name")}
                        </Typography>
                        <input
                          required
                          type="text"
                          defaultValue={selectedPet.fullname}
                          id="fullname_edit"
                          className="form-control"
                        />
                      </div>

                      <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                        <div className="d-flex col-md-5 flex-column">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              marginTop: "10px",
                            }}
                          >
                            {t("pet")}
                          </Typography>
                          <select
                            required
                            id="type_edit"
                            className="form-select btn"
                            defaultValue={selectedPet.type}
                            onChange={(e) => {
                              setSelectedPet({
                                ...selectedPet,
                                type: e.target.value,
                              });
                              dispatch(getBreeds(e.target.value));
                            }}
                            aria-label="Default select example"
                            style={{
                              padding: "8px",
                              backgroundColor: "#637579",
                              borderRadius: "5px",
                              marginBlock: "5px",
                            }}
                          >
                            <option
                              value=""
                              style={{ backgroundColor: "#0a313b" }}
                              disabled
                            >
                              {t("select")}
                            </option>
                            {allBreeds &&
                              allBreeds.map((allBreed, index) => (
                                <option
                                  key={index}
                                  value={allBreed}
                                  style={{ backgroundColor: "#0a313b" }}
                                >
                                  {t(allBreed)}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="d-flex col-md-5 flex-column">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              marginTop: "10px",
                            }}
                          >
                            {t("breed")}
                          </Typography>
                          <select
                            required
                            id="breed_edit"
                            className="form-select btn"
                            value={selectedPet.breed}
                            onChange={(e) =>
                              setSelectedPet({
                                ...selectedPet,
                                breed: e.target.value,
                              })
                            }
                            aria-label="Default select example"
                            style={{
                              backgroundColor: "#637579",
                              padding: "8px",
                              borderRadius: "5px",
                              marginBlock: "5px",
                            }}
                          >
                            <option
                              style={{ backgroundColor: "#0a313b" }}
                              value={""}
                              disabled
                            >
                              {t("select")}
                            </option>
                            {breeds &&
                              breeds.map((breed) => (
                                <option
                                  key={breed}
                                  value={breed}
                                  style={{ backgroundColor: "#0a313b" }}
                                >
                                  {breed}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                        <div className="d-flex col-12 col-md-3 flex-column">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              marginTop: "10px",
                            }}
                          >
                            {t("size")}{" "}
                            <span style={{ fontWeight: "lighter" }}>
                              ({t("lbs")})
                            </span>
                          </Typography>
                          <input
                            required
                            id="size_edit"
                            step={"0.1"}
                            defaultValue={selectedPet.size}
                            type="number"
                            className="form-control"
                          />
                        </div>
                        <div className="d-flex col-12 col-md-4 flex-column">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              marginTop: "10px",
                            }}
                          >
                            {t("gender")}
                          </Typography>
                          <select
                            required
                            id="gender_edit"
                            className="form-select btn"
                            defaultValue={selectedPet.gender}
                            aria-label="Default select example"
                            style={{
                              backgroundColor: "#637579",
                              padding: "8px",
                              borderRadius: "5px",
                              marginBlock: "5px",
                            }}
                          >
                            <option
                              disabled
                              value={""}
                              style={{ backgroundColor: "#0a313b" }}
                            >
                              {t("select")}
                            </option>
                            <option
                              value="male"
                              style={{ backgroundColor: "#0a313b" }}
                            >
                              {t("male")}
                            </option>
                            <option
                              value="female"
                              style={{ backgroundColor: "#0a313b" }}
                            >
                              {t("female")}
                            </option>
                          </select>
                        </div>
                        <div className="d-flex col-12 col-md-3 flex-column">
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              marginTop: "10px",
                            }}
                          >
                            {t("color")}
                          </Typography>
                          <input
                            required
                            id="color_edit"
                            type="text"
                            defaultValue={selectedPet.color}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-md-10 col">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                            marginTop: "10px",
                          }}
                        >
                          {t("birthday")}
                        </Typography>
                        <DatePicker
                          required
                          dateFormat={"dd/MM/yyyy"}
                          wrapperClassName="w-100"
                          selected={birthday}
                          onChange={(date) => {
                            setBirthday(date);
                          }}
                        />
                      </div>

                      <div className="col-md-10 col">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                            marginTop: "10px",
                          }}
                        >
                          {t("pet_introduction")}
                        </Typography>
                        <textarea
                          name="desc"
                          id="desc_edit"
                          defaultValue={selectedPet.desc}
                          className="form-control"
                          style={{ resize: "none", height: "200px" }}
                        ></textarea>
                      </div>

                      <div className="d-flex flex-row flex-wrap justify-content-center w-100 mt-2">
                        <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                          <Typography
                            sx={{ fontWeight: "bold", color: "black" }}
                          >
                            {t("medicines")}
                          </Typography>
                          <textarea
                            name="edit_medicine"
                            id="edit_medicine"
                            defaultValue={selectedPet.medicine}
                            className="form-control"
                            style={{ resize: "none", height: "200px" }}
                          ></textarea>
                        </div>
                        <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                          <Typography
                            sx={{ fontWeight: "bold", color: "black" }}
                          >
                            {t("allergy")}
                          </Typography>
                          <textarea
                            name="edit_allergy"
                            id="edit_allergy"
                            defaultValue={selectedPet.allergy}
                            className="form-control"
                            style={{ resize: "none", height: "200px" }}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-10 col">
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "black",
                            marginTop: "10px",
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {t("special_cases")}
                          <span style={{ fontSize: "12px", color: "red" }}>
                            {t("max_char")}
                          </span>
                        </Typography>
                        <textarea
                          name="edit_case"
                          id="edit_case"
                          defaultValue={selectedPet.special_case}
                          className="form-control"
                          style={{ resize: "none", height: "200px" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        style={{ width: "80%", justifyContent: "center" }}
                        className="btn btn-primary"
                      >
                        {t("save")}
                      </button>
                    </div>
                  </form>
                </Box>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default PetsComponent;
