import React from "react";
import NewsLetter from "../components/NewsLetter";
import ContactForm from "../components/contacts/ContactForm";
import BreadCumb from "../components/Breadcrumb";
import { useTranslation } from "react-i18next";

function ContactPage() {
  const { t } = useTranslation();
  return (
    <main>
      <BreadCumb path={t("contact_us")} />
      <ContactForm />
      {/* <NewsLetter /> */}
    </main>
  );
}

export default ContactPage;
