import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCumb from "../../../Breadcrumb";
import axios from "axios";
import StarRatings from "react-star-ratings";
import { useTranslation } from "react-i18next";

function ProviderProfile() {
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();

  useEffect(() => {
    const getOneProvider = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}users/${params.id}`);
        setValues(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getOneProvider();
  }, [params]);

  return (
    <div>
      <BreadCumb path={t("provider")} />
      <section className="shop-details-area pt-110 pb-50">
        {values && (
          <div className="container">
            <div className="shop-details-wrap">
              <div className="row">
                <div className="col-5">
                  <div className="shop-details-img-wrap">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane show active"
                        id="item-one"
                        role="tabpanel"
                        aria-labelledby="item-one-tab"
                      >
                        <div className="shop-details-img">
                          <img
                            src={
                              values.user?.image
                                ? values.user.image
                                : "/img/unuser.png"
                            }
                            width={"100%"}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-two"
                        role="tabpanel"
                        aria-labelledby="item-two-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details02.jpg" alt="" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-three"
                        role="tabpanel"
                        aria-labelledby="item-three-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details03.jpg" alt="" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-four"
                        role="tabpanel"
                        aria-labelledby="item-four-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details04.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="shop-details-content">
                    <span>{t("provider")}</span>
                    <h2 className="title">{values.provider.fullname}</h2>
                    <div className="shop-details-review">
                      <StarRatings
                        rating={values?.averageStars}
                        starHoverColor="orange"
                        starRatedColor="orange"
                        starDimension="20px"
                        starSpacing="1px"
                        numberOfStars={5}
                        name="rating"
                      />
                      <span style={{ marginLeft: "5px", marginTop: "5px" }}>
                        ( {values.feedbacks.length} {t("review")} )
                      </span>
                    </div>
                    <div className="shop-details-dimension">
                      <span>{t("introduction")}: </span>
                    </div>
                    <p>{values.provider.introduction}</p>
                    <div className="shop-details-dimension">
                      <span>{t("service_categories")} :</span>
                      {values.provider.categoryServices[0] &&
                        values.provider.categoryServices[0].categoryServices.map(
                          (category, index) => (
                            <div
                              key={index}
                              className="shop-details-dimension mt-3"
                            >
                              <span>{t(Object.keys(category))} :</span>
                              <ul>
                                {Object.entries(category).map(
                                  ([pet, services]) => (
                                    <div
                                      key={services}
                                      className="d-flex flex-row"
                                    >
                                      {services.map((service, index) => (
                                        <li
                                          key={pet}
                                          style={{
                                            display: "block",
                                            border: "1px solid #ebebeb",
                                            backgroundColor: "#f5f2eb",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                            color: "#676666",
                                            padding: "7px 18px",
                                          }}
                                        >
                                          <span key={index}>{t(service)}</span>
                                        </li>
                                      ))}
                                    </div>
                                  )
                                )}
                              </ul>
                            </div>
                          )
                        )}
                    </div>
                    <div className="shop-details-bottom">
                      <ul>
                        {/* <li className="d-flex align-items-center">
                          <span className="title">{t("categories")} :</span>
                          <span
                            style={{
                              color: "gray",
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                          >
                            123
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <span className="title">SKU :</span>
                          <span
                            style={{
                              color: "gray",
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                          >
                            ABC123
                          </span>
                        </li> */}
                        <li className="sd-share">
                          <span className="title">{t("share_now")} :</span>
                          <a href="/#">
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a href="/#">
                            <i className="fab fa-twitter" />
                          </a>
                          <a href="/#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="product-desc-wrap">
                <ul className="nav nav-tabs" id="myTabTwo" role="tablist">
                  <li className="nav-item">
                    <div className="nav-link" style={{ color: "red" }}>
                      {t("Reviews")} ({values.feedbacks.length})
                    </div>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContentTwo">
                  <div
                    className="tab-pane fade show active"
                    id="details"
                    role="tabpanel"
                    aria-labelledby="details-tab"
                  >
                    <div className="product-desc-content">
                      {values.feedbacks.map((feedback, index) => (
                        <div key={index}>
                          <h5>
                            {feedback.user.fullname + " "}(
                            {
                              <StarRatings
                                rating={feedback.feedback.star}
                                starHoverColor="orange"
                                starRatedColor="orange"
                                starDimension="20px"
                                starSpacing="1px"
                                numberOfStars={5}
                                name="rating"
                              />
                            }
                            )
                          </h5>
                          <p>{feedback.feedback.comment}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
export default ProviderProfile;
