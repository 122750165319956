import React from "react";
import BreadCumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const Selling = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BreadCumb path={t("f_selling")} />
      <div className="container my-5">
        <h3 style={{ color: "black" }}>{t("f_selling")}</h3>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            1. TARAFLAR
          </div>
          {`İşbu Mesafeli Satış Sözleşmesi, Elitsoft Bilişim Sistemleri Anonim Şirketi (Beşiktaş Vergi
Dairesi-Vergi No: 3321359124, Adres: Merkez Mahallesi Şamlı Sokak No: 31 İç Kapı No: 5
Avcılar /İstanbul) ile bu Sözleşme’deki hizmetlerden faydalanmak isteyen Alıcı ve/veya
Kullanıcı arasında akdedilmiştir.

Alıcı ve/veya Kullanıcı, sözleşme konusu mal ve hizmet siparişine ilişkin işbu sözleşmeyi
kabul etmekle sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri
ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            2. TANIMLAR
          </div>
          {`İşbu Mesafeli Satış Sözleşmesi, Elitsoft Bilişim Sistemleri Anonim Şirketi (Beşiktaş Vergi
Dairesi-Vergi No: 3321359124, Adres: Merkez Mahallesi Şamlı Sokak No: 31 İç Kapı No: 5
Avcılar /İstanbul) ile bu Sözleşme’deki hizmetlerden faydalanmak isteyen Alıcı ve/veya
Kullanıcı arasında akdedilmiştir.

Alıcı ve/veya Kullanıcı, sözleşme konusu mal ve hizmet siparişine ilişkin işbu sözleşmeyi
kabul etmekle sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri
ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            3. KONU
          </div>
          {`İşbu Sözleşme, ALICI ve/veya KULLANICI’nın, SATICI’ya ait internet sitesi üzerinden
elektronik ortamda siparişini verdiği ve internet sitesi üzerinden satış fiyatı belirtilen ürün
veya hizmetin satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında
Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve
yükümlülüklerini düzenler.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            4. SATICI BİLGİLERİ
          </div>
          {`Unvanı : Elitsoft Bilişim Sistemleri Anonim Şirketi
Vergi No : 3321359124
Adres : Merkez Mah. Şamlı Sk. No: 31 İç Kapı No: 5 Avcılar / İstanbul`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            5. SİPARİŞ VEREN ALICI ve/veya KULLANICI BİLGİLERİ
          </div>
          {`Alıcı Kişi :
Teslimat Adresi :
Telefon :
Eposta/kullanıcı adı :`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            6. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ
          </div>
          {`Malın/ Ürünün /Ürünlerin/ Hizmetin temel özelliklerini (türü, miktarı, marka/modeli, rengi,
adedi) SATICI’ya ait internet sitesinde yayınlanmaktadır. Satıcı tarafından kampanya
düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz.
Kampanya koşulları, kampanya bitiş tarihine kadar geçerlidir.
Sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler güncelleme yapılana ve
değiştirilene kadar geçerlidir.

Süreli olarak ilan edilen fiyatlar ise belirtilen süre sonuna kadar
geçerlidir.

Ürün sevkiyat masrafı olan kargo ücreti ve hizmet taşıma bedeli ALICI tarafından
ödenecektir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            7. FATURA BİLGİLERİ
          </div>
          {`Ad/Soyad/Unvan :
Adres :
Telefon :
Eposta/kullanıcı adı :
Fatura teslim : Fatura sipariş teslimatı sırasında fatura adresine sipariş ile birlikte
teslim edilecektir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            8. ÖDEME
          </div>
          {`SATICI, internet sitesinde ALICI ve/veya KULLANICI tarafından ödemenin
gerçekleştirilmesini sağlayacak güvenli ödeme sistemine ilişkin yazılımı bulundurmakla
yükümlüdür. İşbu sözleşme’nin ödeme hizmeti sağlayıcı tarafı iyzi Ödeme ve Elektronik Para
Hizmetleri A.Ş. (İYZİCO)dir. ALICI ve/veya KULLANICI tarafından ödeme emrini vermek
için kullanılan kart, cep telefonu, şifre ve benzeri kişiye özel her türlü araç ödeme aracını,
ALICI ve/veya KULLANICI adına iyzico nezdinde açılan ve ödeme işleminin
yürütülmesinde kullanılan hesaplar ise ödeme hesabını teşkil etmektedir.

ALICI tarafından SATICI’ya ait internet sitesinden hizmet alımının söz konusu olduğu
hallerde; SATICI, komisyon alacağı dışındaki tutarı evcil hayvan gezdiricisi/ bakıcısı hesabına
gönderecektir.

Yine, ALICI tarafından SATICI’ya ait internet sitesinden ürün/ mal alımının söz konusu
olduğu hallerde; SATICI, komisyon alacağı dışındaki tutarı ilgili Petshop hesabına
gönderecektir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            9. GENEL HÜKÜMLER
          </div>
          {`ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün veya hizmetin temel
nitelikleri, satış fiyatı, hizmet bedeli ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup,
bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt
eder. ALICI’nın; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış
sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi gereken adresi,
siparişi verilen ürünlere ait temel özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme ve
teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.

Sözleşme konusu her bir ürün, 30 günlük yasal süreyi aşmamak kaydı ile ALICI' nın yerleşim
yeri uzaklığına bağlı olarak internet sitesindeki ön bilgiler kısmında belirtilen süre zarfında
ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde
ürünün ALICI’ya teslim edilememesi durumunda, ALICI’nın sözleşmeyi feshetme hakkı
saklıdır.

SATICI, Sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun ve varsa
garanti belgeleri, kullanım kılavuzları işin gereği olan bilgi ve belgeler ile teslim etmeyi, her
türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde
işi doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet kalitesini koruyup yükseltmeyi,
işin ifası sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket etmeyi
kabul, beyan ve taahhüt eder.

SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı bilgilendirmek
ve açıkça onayını almak suretiyle eşit kalite ve fiyatta farklı bir ürün tedarik edebilir.

SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin imkânsızlaşması halinde
sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, öğrendiği tarihten itibaren
3 gün içinde yazılı olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli
ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder.

ALICI, Sözleşme konusu ürünün teslimatı için işbu Sözleşme’yi elektronik ortamda teyit
edeceğini, herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi ve/veya banka
kayıtlarında iptal edilmesi halinde, SATICI’nın sözleşme konusu ürünü teslim
yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.

ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği adresteki kişi ve/veya
kuruluşa tesliminden sonra ALICI'ya ait kredi kartının yetkisiz kişilerce haksız kullanılması
sonucunda sözleşme konusu ürün bedelinin ilgili banka veya finans kuruluşu tarafından
SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3 gün içerisinde nakliye
gideri SATICI’ya ait olacak şekilde SATICI’ya iade edeceğini kabul, beyan ve taahhüt eder.

SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve tarafların borçlarını
yerine getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir sebepler
halleri nedeni ile sözleşme konusu ürünü süresi içinde teslim edemez ise, durumu ALICI'ya
bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme
konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesini SATICI’dan talep etme hakkını haizdir.
ALICI tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile yaptığı ödemelerde, ürün
tutarı 14 gün içinde kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı
ödemelerde ise, ürün tutarı, siparişin ALICI tarafından iptal edilmesinden sonra 14 gün
içerisinde ilgili bankaya iade edilir. ALICI, SATICI tarafından kredi kartına iade edilen
tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı
bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın hesaplarına yansıması halinin
tamamen banka işlem süreci ile ilgili olduğundan, ALICI, olası gecikmeler için SATICI’yı
sorumlu tutamayacağını kabul, beyan ve taahhüt eder.

SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya daha sonra kendisi
tarafından güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim
bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim,
pazarlama, bildirim ve diğer amaçlarla ALICI’ya ulaşma hakkı bulunmaktadır. ALICI, işbu
sözleşmeyi kabul etmekle SATICI’nın kendisine yönelik yukarıda belirtilen iletişim
faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.

ALICI, sözleşme konusu mal/hizmeti teslim almadan önce muayene edecek; ezik, kırık,
ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti teslim almayacaktır. Teslim alınan
mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir.

Teslimden sonra mal/hizmetin özenle korunması borcu, ALICI’ya aittir. Cayma hakkı
kullanılacaksa mal/hizmet kullanılmamalıdır. Fatura iade edilmelidir.

ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi olmaması veya ürünün
ALICI’ya tesliminden evvel, siparişte kullanılan kredi kartına ilişkin güvenlik açığı tespit
edilmesi halinde, SATICI, kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte
kullanılan kredi kartının bir önceki aya ait ekstresini yahut kart hamilinin bankasından kredi
kartının kendisine ait olduğuna ilişkin yazıyı ibraz etmesini ALICI’dan talep edebilir.
ALICI’nın talebe konu bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş
dondurulacak olup, mezkur taleplerin 24 saat içerisinde karşılanmaması halinde ise SATICI,
siparişi iptal etme hakkını haizdir.

ALICI, SATICI’ya ait internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin
gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı
tüm zararları, SATICI’nın ilk bildirimi üzerine derhal, nakden ve defaten tazmin edeceğini
beyan ve taahhüt eder.

ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi
ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve
cezai yükümlülükler tamamen ve münhasıran ALICI’yı bağlayacaktır.

ALICI, SATICI’ya ait internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka
aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının
maddi ve manevi haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının
hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.)
işlemlerde bulunamaz.

SATICI’ya ait internet sitesinin üzerinden, SATICI’nın kendi kontrolünde olmayan ve/veya
başkaca üçüncü kişilerin sahip olduğu ve/veya işlettiği başka web sitelerine ve/veya başka
içeriklere link verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı sağlamak amacıyla konmuş
olup herhangi bir web sitesini veya o siteyi işleten kişiyi desteklememekte ve Link verilen
web sitesinin içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır.

Kullanıcı, kendisine ait bilgilerin sadece Servis’in verilmesini temin amacıyla iyzico
Gizlilik&Kişisel Veri Politikası’nın https://www.iyzico.com/gizlilik-politikasi/ adresinde
yayınlanan en güncel halinde belirtildiği şekilde iyzico tarafından işlenmesine, saklanmasına
ve benzeri işlemlerin (sisteme tanımlamak ve kayıt etmek) yapılmasına ve gerektiğinde
üçüncü kişiler ile paylaşılmasına ilişkin bilgilendirildiğini kabul etmektedir. iyzico kişisel
verilerin “gizli bilgi” olduğunun, kendisine bu amaçla verilen kişisel bilgilerin gizliliğini
temin için gerekli özeni göstermekle yükümlü olduğunun ve 6698 sayılı Kişisel Verilen
Korunması Kanunu’na ve diğer güncel mevzuatlara uygun davranacağının bilincindedir.

İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal
nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, SATICI’yı bu ihlallerin hukuki ve
cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal
ettirilmesi halinde, SATICI’nın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı
tazminat talebinde bulunma hakkı saklıdır.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            10. CAYMA HAKKI
          </div>
          {`10.1. ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine
veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde,
SATICI’ya bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir
gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir. Hizmet
sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten
itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına
başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından
kaynaklanan masraflar SATICI’ ya aittir. ALICI, iş bu sözleşmeyi kabul etmekle, cayma
hakkı konusunda bilgilendirildiğini peşinen kabul eder.

10.2. Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya iadeli
taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede
düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış
olması şarttır. Bu hakkın kullanılması halinde,

a) 3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün
faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte
gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE
FATURASI kesilmediği takdirde tamamlanamayacaktır.)

b) İade formu,

c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve
hasarsız olarak teslim edilmesi gerekmektedir.

d) SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre
içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20
günlük süre içerisinde malı iade almakla yükümlüdür.

e) ALICI’nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya
iade imkânsızlaşırsa ALICI kusuru oranında SATICI’nın zararlarını tazmin etmekle
yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması
sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.

f) Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit
tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal
edilir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            11. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER
          </div>
          {`ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan ve geri
gönderilmeye müsait olmayan, tek kullanımlık ürünler, çabuk bozulma tehlikesi olan veya son
kullanma tarihi geçme ihtimali olan mallar, ALICI’ya teslim edilmesinin ardından ALICI
tarafından ambalajı açıldığı takdirde iade edilmesi sağlık ve hijyen açısından uygun olmayan
ürünler, teslim edildikten sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması
mümkün olmayan ürünler, ifa edilen hizmetler veya tüketiciye anında teslim
edilen gayrimaddi malların iadesi Yönetmelik gereği mümkün değildir. Ayrıca Cayma hakkı
süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin cayma
hakkının kullanılması da Yönetmelik gereği mümkün değildir.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            12. TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
          </div>
          {`ALICI, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart
sahibi banka ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya
karşı sorumlu olacağını kabul, beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara
başvurabilir; doğacak masrafları ve vekâlet ücretini ALICI’dan talep edebilir ve her koşulda
ALICI’nın borcundan dolayı temerrüde düşmesi halinde, ALICI, borcun gecikmeli ifasından
dolayı SATICI’nın uğradığı zarar ve ziyanını ödeyeceğini kabul, beyan ve taahhüt eder.`}
        </div>
        <div
          style={{
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            marginBlock: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: "black",
              marginBlock: 5,
            }}
          >
            13. YÜRÜRLÜK
          </div>
          {`ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm
şartlarını kabul etmiş sayılır. SATICI, siparişin gerçekleşmesi öncesinde işbu sözleşmenin
sitede ALICI tarafından okunup kabul edildiğine dair onay alacak şekilde gerekli yazılımsal
düzenlemeleri yapmakla yükümlüdür.
SATICI : Elitsoft Bilişim Sistemleri Anonim Şirketi
ALICI ve/veya KULLANICI :
TARİH :`}
        </div>
      </div>
    </div>
  );
};

export default Selling;
