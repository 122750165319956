import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

function MainSlider() {
  const [banners, setBanners] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const getBanners = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}info/banner/1`);
        setBanners(res.data.infos);
      } catch (error) {
        console.log(error);
      }
    };
    getBanners();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <BrowserRouter>
      <div>
        <section className="slider-area">
          <Slider className="slider-active" {...settings}>
            {banners &&
              banners.map((info) => (
                <div key={info._id}>
                  <div
                    className="single-slider slider-bg d-flex align-items-center"
                    style={{
                      backgroundImage: `url(${
                        info.image_url
                          ? info.image_url
                          : "img/slider/slider_bg01.jpg"
                      })`,
                    }}
                  >
                    <div className="container custom-container">
                      <div className="row">
                        <div className="col-xl-5 col-lg-7 col-md-10">
                          <div className="slider-content">
                            <div className="slider-title">
                              <h2
                                className="title"
                                data-animation="fadeInUpBig"
                                data-delay=".2s"
                                data-duration="1.2s"
                              >
                                {info.fullname}
                              </h2>
                            </div>
                            <div className="slider-desc">
                              <p
                                className="desc"
                                data-animation="fadeInUpBig"
                                data-delay=".4s"
                                data-duration="1.2s"
                              >
                                {info.desc}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </section>
        <div className="find-area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                {/* <form>
                  <div className="find-wrap">
                    <div className="location">
                      <i className="flaticon-location" />
                      <input
                        type="text"
                        defaultValue="Enter City, State. or Zip"
                      />
                    </div>
                    <div className="find-category">
                      <ul style={{ marginLeft: 10 }}>
                        <li>
                          <Link to="/shop">
                            <i className="flaticon-dog" />{" "}
                            <span>{t("find_dog")}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop">
                            <i className="flaticon-happy" /> {t("find_cat")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop">
                            <i className="flaticon-dove" /> {t("find_birds")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="other-find">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ whiteSpace: "normal", textAlign: "center" }}
                        >
                          {t("find_other_pets")}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <Link className="dropdown-item" to="/shop">
                            {t("find_dog")}
                          </Link>
                          <Link className="dropdown-item" to="/shop">
                            {t("find_cat")}
                          </Link>
                          <Link className="dropdown-item" to="/shop">
                            {t("find_birds")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
        <section
          id="why_us"
          className="counter-area counter-bg"
          style={{ backgroundImage: "url('img/bg/counter_bg.jpg')" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="counter-title text-center mb-65">
                  <h6 className="sub-title">{t("why_choose_us")}</h6>
                  <h2 className="title">{t("best_service")}</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  style={{ backgroundColor: "#dc4509" }}
                  className="counter-item"
                >
                  <p style={{ color: "#fefefe" }}>{t("main_slider_title")}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  style={{ backgroundColor: "#dc4509" }}
                  className="counter-item"
                >
                  <p style={{ color: "#fefefe" }}>{t("main_slider_title2")}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  style={{ backgroundColor: "#dc4509" }}
                  className="counter-item"
                >
                  <p style={{ color: "#fefefe" }}>{t("main_slider_title3")}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div
                  style={{ backgroundColor: "#dc4509" }}
                  className="counter-item"
                >
                  <p style={{ color: "#fefefe" }}>{t("main_slider_title4")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </BrowserRouter>
  );
}

export default MainSlider;
