import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import BreadCumb from "../../components/Breadcrumb";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../store/slices/cartSlice";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CategoryItem = ({
  category,
  name,
  depth = 0,
  selectedCategory,
  setSelectedCategory,
  onCategorySelect,
}) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const hasChildren =
    (typeof category === "object" && category !== null) ||
    Array.isArray(category);
  const handleClick = () => {
    setOpen(!open);
    setSelectedCategory(name);
    onCategorySelect(name);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        style={{
          paddingLeft: depth * 20,
          backgroundColor: selectedCategory === name ? "#e0e0e0" : "inherit",
        }}
      >
        {hasChildren && (
          <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        )}
        <ListItemText primary={t(name)} />
      </ListItem>
      {Array.isArray(category) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.map((item, index) => (
              <ListItem
                button
                key={index}
                style={{
                  paddingLeft: (depth + 1) * 20,
                  backgroundColor:
                    selectedCategory === item ? "#e0e0e0" : "inherit",
                }}
                onClick={() => {
                  setSelectedCategory(item);
                  onCategorySelect(item);
                }}
              >
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.entries(category).map(([key, value]) => (
              <CategoryItem
                key={key}
                category={value}
                name={key}
                depth={depth + 1}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                onCategorySelect={onCategorySelect}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

function ProductsCMode() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [petShops, setPetShops] = useState([]);
  const [selectedPetShop, setSelectedPetShop] = useState("");
  const [sort, setSort] = useState("lastest");
  const query = useQuery();
  const searchQuery = query.get("search");
  const categoryQuery = query.get("category");
  const priceQuery = query.get("price");
  const petShopQuery = query.get("petshop");
  const dispatch = useDispatch();
  const history = useHistory();
  const cartItems = useSelector((state) => state.cart.items);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}products`, {
          params: {
            search: searchQuery,
            category: categoryQuery,
            price: priceQuery,
            petshop: petShopQuery,
            sort: sort,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProducts();
  }, [searchQuery, categoryQuery, priceQuery, petShopQuery, sort]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}products/category`);
        setCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCategories();
  }, []);

  useEffect(() => {
    const getPetshops = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}petshops`);
        setPetShops(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getPetshops();
  }, []);

  const handlePriceRangeSelect = (range) => {
    const updatedPriceRange = selectedPriceRange === range ? "" : range;

    if (searchQuery && categoryQuery && petShopQuery) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&price=${updatedPriceRange}&petshop=${petShopQuery}`
      );
    } else if (searchQuery && categoryQuery) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&price=${updatedPriceRange}`
      );
    } else if (searchQuery && petShopQuery) {
      history.push(
        `/products?search=${searchQuery}&price=${updatedPriceRange}&petshop=${petShopQuery}`
      );
    } else if (searchQuery) {
      history.push(
        `/products?search=${searchQuery}&price=${updatedPriceRange}`
      );
    } else if (categoryQuery && petShopQuery) {
      history.push(
        `/products?category=${categoryQuery}&price=${updatedPriceRange}&petshop=${petShopQuery}`
      );
    } else if (categoryQuery) {
      history.push(
        `/products?category=${categoryQuery}&price=${updatedPriceRange}`
      );
    } else if (petShopQuery) {
      history.push(
        `/products?price=${updatedPriceRange}&petshop=${petShopQuery}`
      );
    } else {
      history.push(`/products?price=${updatedPriceRange}`);
    }

    setSelectedPriceRange(updatedPriceRange);
  };

  const handlePetShopSelect = (petShop) => {
    const updatedPetShop = selectedPetShop === petShop ? "" : petShop;

    if (searchQuery && categoryQuery && selectedPriceRange) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&price=${selectedPriceRange}&petshop=${updatedPetShop}`
      );
    } else if (searchQuery && categoryQuery) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&petshop=${updatedPetShop}`
      );
    } else if (searchQuery && selectedPriceRange) {
      history.push(
        `/products?search=${searchQuery}&price=${selectedPriceRange}&petshop=${updatedPetShop}`
      );
    } else if (searchQuery) {
      history.push(`/products?search=${searchQuery}&petshop=${updatedPetShop}`);
    } else if (categoryQuery && selectedPriceRange) {
      history.push(
        `/products?category=${categoryQuery}&price=${selectedPriceRange}&petshop=${updatedPetShop}`
      );
    } else if (categoryQuery) {
      history.push(
        `/products?category=${categoryQuery}&petshop=${updatedPetShop}`
      );
    } else if (selectedPriceRange) {
      history.push(
        `/products?price=${selectedPriceRange}&petshop=${updatedPetShop}`
      );
    } else {
      history.push(`/products?petshop=${updatedPetShop}`);
    }

    setSelectedPetShop(updatedPetShop);
  };

  const handleAddCart = (product) => {
    dispatch(addItem(product));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.currentTarget.search.value;
    history.push(`/products?search=${searchQuery}`);
    setTimeout(() => {
      window.location.reload();
      window.ReactNativeWebView.postMessage("reload");
    }, 100);
  };

  const handleCategorySelect = (category) => {
    let updatedCategories;

    if (selectedCategory.includes(category)) {
      updatedCategories = selectedCategory.filter((cat) => cat !== category);
    } else {
      updatedCategories = [...selectedCategory, category];
    }

    const categoryQuery = updatedCategories.join(",");

    if (searchQuery && selectedPriceRange && selectedPetShop) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&price=${selectedPriceRange}&petshop=${selectedPetShop}`
      );
    } else if (searchQuery && selectedPriceRange) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&price=${selectedPriceRange}`
      );
    } else if (searchQuery && selectedPetShop) {
      history.push(
        `/products?search=${searchQuery}&category=${categoryQuery}&petshop=${selectedPetShop}`
      );
    } else if (searchQuery) {
      history.push(`/products?search=${searchQuery}&category=${categoryQuery}`);
    } else if (selectedPriceRange && selectedPetShop) {
      history.push(
        `/products?category=${categoryQuery}&price=${selectedPriceRange}&petshop=${selectedPetShop}`
      );
    } else if (selectedPriceRange) {
      history.push(
        `/products?category=${categoryQuery}&price=${selectedPriceRange}`
      );
    } else if (selectedPetShop) {
      history.push(
        `/products?category=${categoryQuery}&petshop=${selectedPetShop}`
      );
    } else {
      history.push(`/products?category=${categoryQuery}`);
    }

    setSelectedCategory(updatedCategories);
  };

  const handleSort = () => {};

  return (
    <div>
      <BreadCumb path={t("my_products")} />
      <div className="shop-area pt-60 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-8 order-2 order-lg-0">
              <aside className="shop-sidebar">
                <div className="widget">
                  <h4 className="sidebar-title">{t("category")}</h4>
                  <div className="shop-cat-list">
                    <List component="nav">
                      {categories.map((category, index) => (
                        <CategoryItem
                          key={index}
                          category={Object.values(category)[0]}
                          name={Object.keys(category)[0]}
                          selectedCategory={selectedCategory}
                          setSelectedCategory={setSelectedCategory}
                          onCategorySelect={handleCategorySelect}
                        />
                      ))}
                    </List>
                  </div>
                </div>
                <div className="widget">
                  <h4 className="sidebar-title">{t("price_range")}</h4>
                  <div className="shop-price-list">
                    <List component="nav">
                      {["0-50", "50-100", "100-150"].map((range) => (
                        <ListItem
                          button
                          key={range}
                          style={{
                            backgroundColor:
                              selectedPriceRange === range
                                ? "#e0e0e0"
                                : "inherit",
                          }}
                          onClick={() => handlePriceRangeSelect(range)}
                        >
                          <ListItemText
                            primary={`${range.replace("-", "₺ -")}₺`}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>
                <div className="widget">
                  <h4 className="sidebar-title">{t("pet_shops")}</h4>
                  <div className="shop-petshop-list">
                    <List component="nav">
                      {petShops.map((petshop, index) => (
                        <ListItem
                          button
                          key={index}
                          style={{
                            backgroundColor:
                              selectedPetShop === petshop.name
                                ? "#e0e0e0"
                                : "inherit",
                          }}
                          onClick={() => handlePetShopSelect(petshop.name)}
                        >
                          <ListItemText primary={petshop.name} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="search-form mb-10">
                  <form onSubmit={handleSearch}>
                    <input
                      id="search"
                      type="text"
                      name="search"
                      placeholder={t("Search_Here")}
                      defaultValue={searchQuery}
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
                <div className="shop-page-meta mb-30">
                  <div className="shop-showing-result">
                    <p>
                      {t("total_items")}: {products.length}
                    </p>
                  </div>
                  <div className="shop-short-by">
                    <form>
                      <label htmlFor="shortBy">{t("sort_by")}</label>
                      <select
                        onChange={(value) => {
                          setSort(value.target.value);
                        }}
                        id="shortBy"
                        className="selected"
                      >
                        <option value="latest">{t("Sort_by_latest")}</option>
                        <option value="price_asc">{t("low_to_high")}</option>
                        <option value="price_desc">{t("high_to_low")}</option>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {products &&
                    products.map((product) => (
                      <div key={product._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link to={`/products/${product._id}`}>
                              <img
                                src={
                                  product.images[0] ||
                                  "/img/product/product_details.jpg"
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="shop-content">
                            <span>
                              {product.category[product.category.length - 1]}
                            </span>
                            <h4 className="title">
                              <Link to={`/products/${product._id}`}>
                                {product.name}
                              </Link>
                            </h4>
                            <div className="shop-content-bottom">
                              <span className="price">{product.price}₺</span>
                              <span
                                onClick={() => {
                                  history.push("/login");
                                }}
                                style={{
                                  color: "#f04336",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                                className="add-cart"
                              >
                                {t("ADD")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="shop-page-meta mb-30">
                  <div className="shop-showing-result">
                    <p>
                      {t("total_items")}: {products.length}
                    </p>
                  </div>
                  <div className="shop-short-by">
                    <form>
                      <label htmlFor="shortBy">{t("sort_by")}</label>
                      <select
                        onChange={(value) => {
                          setSort(value.target.value);
                        }}
                        id="shortBy"
                        className="selected"
                      >
                        <option value="latest">{t("Sort_by_latest")}</option>
                        <option value="price_asc">{t("low_to_high")}</option>
                        <option value="price_desc">{t("high_to_low")}</option>
                      </select>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsCMode;
