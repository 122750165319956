import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "./slices/locationSlice";
import userTypeReducer from "./slices/userTypeSlice";
import breedReducer from "./slices/breedSlice";
import petReducer from "./slices/petSlice";
import offerReducer from "./slices/offerSlice";
import cartReducer from "./slices/cartSlice";
import adoptReducer from "./slices/adoptSlice";

const store = configureStore({
  reducer: {
    location: locationReducer,
    userType: userTypeReducer,
    breed: breedReducer,
    pet: petReducer,
    offer: offerReducer,
    cart: cartReducer,
    adopt: adoptReducer 
  },
});

export default store;
