import React, { useState } from "react";
import { Modal, Box, Typography, TextField } from "@mui/material";
import DividerComponent from "../../../Divider/Divider";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useTranslation } from "react-i18next";

function OfferList({ values }) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [base64con, setBase64con] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    fullname: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const history = useHistory();

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "60%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedOffer(null);
    setCardInfo({
      fullname: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    });
  };

  const handleClosePaymentModal = () => {
    setModalVisible(false);
    setSelectedOffer(null);
    setCardInfo({
      fullname: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    });
  };

  const validateInput = () => {
    const newErrors = {};

    // Kart Numarası doğrulaması
    const cardNumberRegex = /^\d{16}$/;
    if (!cardNumberRegex.test(cardInfo.cardNumber)) {
      newErrors.cardNumber = t("Please enter a valid 16-digit card number");
    }

    // Son Kullanma Tarihi doğrulaması
    const expiryDateRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    if (!expiryDateRegex.test(cardInfo.expiryDate)) {
      newErrors.expiryDate = t("Please enter a valid expiry date (MM/YY)");
    }

    // CVV doğrulaması
    const cvvRegex = /^\d{3,4}$/;
    if (!cvvRegex.test(cardInfo.cvv)) {
      newErrors.cvv = t("Please enter a valid CVV");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Hatalar boşsa true döndür
  };

  const handleAcceptOffer = async (e, offerId) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const currentDate = new Date();
      const startDate = new Date(selectedOffer.service.dates.startDate);

      const status = startDate > currentDate ? 1 : 2;
      const selectedPrice = selectedOffer.price;
      const selectedUserId = selectedOffer.provider._id;

      const payload = {
        status: status,
        cardInfo,
        price: selectedPrice,
        providerId: selectedUserId,
      };

      await axios
        .put(`${API_URL}offers/result/${offerId}`, payload, {
          headers: {
            "petapp-auth-token": token,
          },
        })
        .then(async (res) => {
          if (res.data.message) {
            setModalVisible(false);
            setPaymentModal(true);
            setBase64con(res.data.threeDSHtmlContent);
          }
        })
        .then(async (resp) => {
          if (resp.status === 200) {
            setIsConfirm(true);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "There was an issue processing your payment. Please try again.",
      });
    }
  };

  const handleOfferModalForm = async (e) => {
    e.preventDefault();

    if (!validateInput()) {
      return; // Eğer doğrulama geçerli değilse, işlemi durdur
    }

    handleAcceptOffer(e, selectedOffer._id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "expiryDate") {
      // Ay/Yıl formatı için (MM/YY)
      let formattedValue = value.replace(/\D/g, ""); // Sadece sayıları al
      if (formattedValue.length >= 3) {
        formattedValue =
          formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4);
      }
      setCardInfo({
        ...cardInfo,
        [name]: formattedValue,
      });
    } else {
      setCardInfo({
        ...cardInfo,
        [name]: value,
      });
    }
  };

  return (
    <div className="shop-area pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="shop-wrap">
              <div className="row justify-content-center">
                {values.map((value) => (
                  <div key={value._id} className="col-lg-4 col-sm-6">
                    <div className="shop-item mb-55">
                      <div className="shop-thumb">
                        <Link to={`/app/provider/${value.provider._id}`}>
                          {
                            <img
                              src={
                                values.provider?.image
                                  ? values.provider.image
                                  : "/img/unuser.png"
                              }
                              alt=""
                            />
                          }
                        </Link>
                      </div>
                      <div className="shop-content">
                        <span>{t(value.service.job)}</span>
                        <h4 className="title">
                          <Link to={`/app/provider/${value.provider._id}`}>
                            {value.provider.fullname}
                          </Link>
                        </h4>
                        <p
                          style={{
                            marginBlock: 10,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {value.comment}
                        </p>
                        <div className="shop-content-bottom">
                          <span>{value.price} ₺</span>
                          <span
                            onClick={() => {
                              setModalVisible(true);
                              setSelectedOffer(value);
                            }}
                            style={{ color: "#f04336", cursor: "pointer" }}
                          >
                            {t("accept")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {selectedOffer && (
                <div>
                  <Modal
                    open={modalVisible}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="col-md-6 col-10" sx={boxStyle}>
                      <form
                        className="contact-form"
                        onSubmit={handleOfferModalForm}
                      >
                        <div className="form-grp px-5">
                          <h4 className="title">{t("offer_Information")}</h4>
                          <DividerComponent color={"lightgray"} />
                          <div className="my-3">
                            <Typography style={{ color: "black" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {t("message")}:{" "}
                              </span>{" "}
                              {selectedOffer?.comment}
                            </Typography>
                          </div>
                          <div className="my-3">
                            <Typography style={{ color: "black" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {t("price")}:{" "}
                              </span>
                              {selectedOffer?.price} ₺
                            </Typography>
                          </div>
                          <DividerComponent color={"lightgray"} />
                          <h4 className="title">{t("payment_information")}</h4>
                          <div className="my-3">
                            <TextField
                              label={t("name_surname")}
                              variant="outlined"
                              fullWidth
                              name="fullname"
                              value={cardInfo.fullname}
                              onChange={handleInputChange}
                              error={!!errors.fullname}
                              helperText={errors.fullname}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("card_number")}
                              variant="outlined"
                              fullWidth
                              name="cardNumber"
                              value={cardInfo.cardNumber}
                              onChange={handleInputChange}
                              error={!!errors.cardNumber}
                              helperText={errors.cardNumber}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("expiry")}
                              variant="outlined"
                              fullWidth
                              name="expiryDate"
                              value={cardInfo.expiryDate}
                              onChange={handleInputChange}
                              error={!!errors.expiryDate}
                              helperText={errors.expiryDate}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("CVV")}
                              variant="outlined"
                              fullWidth
                              name="cvv"
                              value={cardInfo.cvv}
                              onChange={handleInputChange}
                              error={!!errors.cvv}
                              helperText={errors.cvv}
                              required
                            />
                          </div>
                          <h4 className="title" style={{ textAlign: "center" }}>
                            {t("do_you_accept_the_offer")}
                          </h4>
                        </div>
                        <div
                          style={{ flexWrap: "wrap" }}
                          className="d-flex justify-content-center"
                        >
                          <button
                            onClick={() => {
                              handleCloseModal();
                            }}
                            style={{
                              justifyContent: "center",
                              marginInline: 5,
                            }}
                            className="btn col-md-4 col-10"
                          >
                            {t("cancel")}
                          </button>
                          <button
                            type="submit"
                            style={{
                              width: "45%",
                              justifyContent: "center",
                              marginInline: 5,
                              backgroundColor: "green",
                            }}
                            className="btn col-md-4 col-10"
                          >
                            {t("accept_and_pay")}
                          </button>
                        </div>
                      </form>
                    </Box>
                  </Modal>
                  <Modal
                    open={paymentModal}
                    onClose={handleClosePaymentModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="col-md-6 col-10" sx={boxStyle}>
                      <iframe
                        title="Ödeme Yanıtı"
                        width="100%"
                        height="500px"
                        srcDoc={base64con}
                        sandbox="allow-same-origin allow-scripts allow-forms"
                      ></iframe>
                      {isConfirm && (
                        <div>
                          <p>
                            Ödeme Başarıyla Alınmıştır. Sayfayı
                            Kapatabilirsiniz.{" "}
                          </p>
                          <button
                            onClick={() => {
                              setTimeout(() => {
                                window.location.reload();
                                window.ReactNativeWebView.postMessage("reload");
                              }, 100);
                            }}
                            className="btn"
                          >
                            {t("close")}
                          </button>
                        </div>
                      )}
                    </Box>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferList;
