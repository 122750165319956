import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("petapp_Language", lng);
  };

  const getCurrentLanguage = () => {
    // const language = i18n.language;
    const language = "tr";

    if (language.includes("tr")) {
      return { code: "tr", name: t("turkey"), flag: "/img/icon/tr.png" };
    } else if (language.includes("en")) {
      return {
        code: "en",
        name: t("english"),
        flag: "/img/icon/united-states.png",
      };
    } else {
      return {
        code: "en",
        name: t("english"),
        flag: "/img/icon/united-states.png",
      };
    }
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("petapp_Language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    console.log("Current language:", i18n.language); // Mevcut dili kontrol edin
  }, [i18n]);

  const currentLanguage = getCurrentLanguage();

  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img src="/img/logo/logo.png" alt="" />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>{t("f_description")}</p>
                </div>
                <div className="footer-contact">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="tel:02122694950">0 (212) 269 49 50</a>
                    </h4>
                    <span>{t("h_call")}</span>
                  </div>
                </div>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">{t("f_title1")}</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="/selling_contract">{t("f_selling")}</Link>
                    </li>
                    <li>
                      <Link to="/kvkk">{t("f_kvkk")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">{t("our_services")}</h4>
                <div className="fw-link">
                  {user && user.registerType === "User" && (
                    <ul>
                      <li>
                        <Link to="/app/user_pets">{t("my_pets")}</Link>
                      </li>
                      <li>
                        <Link to="/app/user_services">{t("my_services")}</Link>
                      </li>
                      <li>
                        <Link to="/app/products">{t("products")}</Link>
                      </li>
                      <li>
                        <Link to="/app/user_settings">{t("settings")}</Link>
                      </li>
                    </ul>
                  )}
                  {user && user.registerType === "Provider" && (
                    <ul>
                      <li>
                        <Link to="/app/pro_service_categories">
                          {t("services")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/app/pro_services">{t("my_services")}</Link>
                      </li>
                      <li>
                        <Link to="/app/my_products">{t("my_products")}</Link>
                      </li>
                      <li>
                        <Link to="/app/pro_settings">{t("settings")}</Link>
                      </li>
                    </ul>
                  )}
                  {!user && (
                    <ul>
                      <li>
                        <Link to="/about_us">{t("f_about")}</Link>
                      </li>
                      <li>
                        <Link to="/our_services">{t("f_services")}</Link>
                      </li>
                      <li>
                        <Link to="/products">{t("f_Products")}</Link>
                      </li>
                      <li>
                        <Link to="/contacts">{t("f_contacts")}</Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img01.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img02.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img03.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img04.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img05.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="/img/images/insta_img06.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="/img/images/footer_shape01.png" alt="" />
        </div>
        <div className="footer-shape shape-two">
          <img src="/img/images/footer_shape02.png" alt="" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="mb-5 text-center">
            <img src="/img/iyzico/logo_band_white@1X.png" alt="" />
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>{t("copyright")}</p>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block">
              <div className="footer-lang">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={currentLanguage.flag} alt="Language Flag" />{" "}
                    {currentLanguage.name}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => changeLanguage("tr")}
                    >
                      <img src="/img/icon/tr.png" alt="" /> {t("turkey")}
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => changeLanguage("en")}
                    >
                      <img src="/img/icon/united-states.png" alt="" />{" "}
                      {t("english")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
