import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import axios from "axios";
import { useTranslation } from "react-i18next";

function ServicesForm() {
  const { t, i18n } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [services, setServices] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    const getServices = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(
          `${API_URL}service/active_services/provider`,
          {
            headers: {
              "petapp-auth-token": token,
            },
          }
        );
        if (response.data.length > 0) {
          setServices(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, [token]);

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const handleCancel = async (service) => {
    const offerId = service.offers[0]._id;
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      Swal.fire({
        title: "What is the reason for cancellation?",
        input: "textarea",
        icon: "question",
        text: "Do you want to cancel the service?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        inputValidator: (value) => {
          return !value && "You need to write something!";
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const payload = {
            status: 5,
            reasonCanceled: result.value,
          };

          const response = await axios.put(
            `${API_URL}offers/result/${offerId}`,
            payload,
            {
              headers: {
                "petapp-auth-token": token,
              },
            }
          );
          if (response.data) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        }
        if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="shop-area pt-60 pb-60">
      {services ? (
        <div ref={dropdownRef} className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="row justify-content-center">
                  {services &&
                    services.map((service, index) => (
                      <div key={service._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link to={`/app/pro_services/${service._id}`}>
                              {service.job === "Care" ? (
                                <img src="/img/service/care.jpg" alt="" />
                              ) : service.job === "Training" ? (
                                <img src="/img/service/training.jpg" alt="" />
                              ) : service.job === "Hotel" ? (
                                <img src="/img/service/hotel.jpg" alt="" />
                              ) : service.job === "Group Walking" ? (
                                <img
                                  src="/img/service/group-walking.jpg"
                                  alt=""
                                />
                              ) : service.job === "Walking" ? (
                                <img src="/img/service/walking.jpg" alt="" />
                              ) : service.job === "Keeper" ? (
                                <img src="/img/service/keeper.jpg" alt="" />
                              ) : service.job === "Hotel Pension" ? (
                                <img
                                  src="/img/service/hotel-pension.jpg"
                                  alt=""
                                />
                              ) : null}
                            </Link>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "10px",
                              width: "42px",
                              height: "42px",
                              backgroundColor: "#f04336",
                              color: "white",
                              padding: "0 14px",
                              borderRadius: "50%",
                              lineHeight: "42px",
                            }}
                          >
                            <i
                              className="fa fa-bars"
                              style={{ cursor: "pointer" }}
                              aria-hidden="true"
                              onClick={() => handleDropdownToggle(index)}
                            ></i>
                            {dropdownIndex === index && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "20px",
                                  right: "0px",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                              >
                                <ul>
                                  <li>
                                    <Button
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        handleCancel(service);
                                      }}
                                      className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                    >
                                      <Cancel
                                        style={{
                                          color: "red",
                                          marginRight: "3px",
                                        }}
                                      />
                                      {t("cancel")}
                                    </Button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="shop-content">
                            <span>{t(service.job)}</span>
                            <h4 className="title">
                              <Link to={`/app/pro_services/${service._id}`}>
                                {service.pet?.fullname}
                              </Link>
                            </h4>
                            <span> {`${service.userDetails.district}`}</span>
                            <div className="shop-content-bottom">
                              <small>
                                {formatDate(service?.dates.startDate)}{" "}
                                {formatTime(service?.dates.startDate)} <br />
                                {formatDate(service?.dates.endDate)}{" "}
                                {formatTime(service?.dates.endDate)}
                              </small>
                              {service.offers[0].status === 1 ? (
                                <small style={{ color: "green" }}>
                                  {t("active")}
                                </small>
                              ) : (
                                <small style={{ color: "green" }}>
                                  {t("in_process")}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src="/img/category/cross.png"
            alt=""
            width={"200px"}
            height={"200px"}
          />
          <p>{t("no_data")}</p>
        </div>
      )}
    </div>
  );
}

export default ServicesForm;
