import React from "react";
import MainSlider from "../components/MainSlider";
import NewsComponent from "../components/News";
import LostPets from "../components/LostPets";
import AdoptPet from "../components/Adopt";
import GroupComponent from "../components/Groups";
import EventsComponent from "../components/Events";
import Faq from "../components/Faq";
import Testimonial from "../components/Testimonial";

function Home() {
  return (
    <main>
      <MainSlider />
      <NewsComponent />
      <Faq />
      {/* <AdoptPet /> */}
      <EventsComponent />
      <GroupComponent />
      <Testimonial />
      <LostPets />
    </main>
  );
}

export default Home;
