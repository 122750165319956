import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import axios from "axios";
import { getPets } from "../../store/slices/petSlice";
import { createBrowserHistory } from "history";
const AddAdoptModal = ({ open, handleClose, selectedPet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const pets = useSelector((state) => state.pet.pets);

  const history = createBrowserHistory();

  useEffect(() => {
    if (token) {
      dispatch(getPets(token));
    }
  }, [dispatch, token]);

  const handleSelectedPet = async (petId) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(`${API_URL}pets/my_pets`, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      dispatch(getPets(token));
    } catch (error) {
      console.log(error);
    }
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const handleSubmitAdopt = async (e) => {
    e.preventDefault();
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        pet: e.target.pet.value,
        desc: e.target.case.value,
      };

      const response = await axios.post(`${API_URL}adopt`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your pet has been successfully added"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              history.push("/#/app/adopt");
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t(error.response?.data?.message) || "An error occurred",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="col-md-10 col-10" sx={boxStyle}>
        <div onClick={handleClose} className="modalClose">
          <img src="/img/close.png" alt="close_button" width={40} height={40} />
        </div>
        <form className="contact-form" onSubmit={handleSubmitAdopt}>
          <div className="d-flex form-grp flex-column justify-content-center align-items-center">
            <div className="d-flex flex-row flex-wrap justify-content-center w-100">
              <div className="d-flex col-md-10 col flex-column">
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    marginTop: "10px",
                  }}
                >
                  {t("my_pets")}
                </Typography>
                <select
                  required
                  name="pet"
                  style={{
                    padding: "8px",
                    backgroundColor: "#637579",
                    borderRadius: "5px",
                    marginBlock: "5px",
                    width: "100%",
                  }}
                  id="pet"
                  className="form-select btn"
                  defaultValue={selectedPet ? selectedPet._id : ""}
                  disabled={selectedPet ? true : false}
                  onChange={(e) => {
                    handleSelectedPet(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  <option
                    style={{ backgroundColor: "#0a313b" }}
                    value={""}
                    disabled
                  >
                    {t("select")}
                  </option>
                  {pets &&
                    pets.map((pet) => (
                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        key={pet._id}
                        value={pet._id}
                      >
                        {pet.fullname}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-md-10 col">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "10px",
                }}
              >
                {t("special_cases")}
              </Typography>
              <textarea
                name="case"
                id="case"
                style={{
                  resize: "none",
                  height: "200px",
                  width: "100%",
                }}
              ></textarea>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "80%", justifyContent: "center" }}
              className="btn"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default AddAdoptModal;
