import React from "react";
import MainSlider from "../../../components/MainSlider";
import NewsComponent from "../../../components/News";
import LostPets from "../../../components/LostPets";
import AdoptPet from "../../../components/Adopt";
import GroupComponent from "../../../components/Groups";
import EventsComponent from "../../../components/Events";
import Faq from "../../Faq";
import Testimonial from "../../Testimonial";

function UserHome() {
  return (
    <main>
      <MainSlider />
      <NewsComponent />
      <Faq />
      {/* <AdoptPet /> */}
      <EventsComponent />
      <GroupComponent />
      <Testimonial />
      <LostPets />
    </main>
  );
}

export default UserHome;
