import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

const initialState = {
  offers: [],
  status: 'idle',
  error: null
};

export const fetchOffers = createAsyncThunk(
  'adopt/fetchOffers',
  async (petId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}adopt/offers/${petId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adoptSlice = createSlice({
  name: 'adopt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.offers = action.payload;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default adoptSlice.reducer;