import React, { useState } from "react";
import axios from "axios";
import BreadCumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState(0); // İlk adım: email gönderme
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        email: email,
      };

      const res = await axios.post(`${API_URL}auth/forgot-password`, payload);

      if (res.status === 200) {
        setStep(1);
      }
    } catch (error) {
      console.log(error);
      setMessage("Error sending email. Please try again.");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        email: email,
        code: code,
        newPassword: newPassword,
      };

      const res = await axios.post(`${API_URL}auth/reset-password`, payload);

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Şifreniz Başarıyla Sıfırlandı",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              history.push("/login");
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: t(error.response.data.message),
      });
      setMessage("Error resetting password. Please try again.");
    }
  };

  return (
    <div>
      <BreadCumb path={t("forgot_password")} />
      <div
        style={{ marginBlock: 20 }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="col-sm-3 text-black">
          {step === 0 ? (
            <form className="contact-form" onSubmit={handleEmailSubmit}>
              <div className="form-grp mb-4">
                <label htmlFor="email">{t("email")} :</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button
                className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                type="submit"
              >
                {t("send")}
              </button>
            </form>
          ) : (
            // İkinci adım: Kod ve yeni şifre girme
            <form className="contact-form" onSubmit={handleResetPassword}>
              <div className="form-grp mb-4">
                <label htmlFor="code">{t("reset_code")}:</label>
                <input
                  type="text"
                  id="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>
              <div className="form-grp mb-4">
                <label htmlFor="newPassword">{t("new_password")}:</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <button
                className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                type="submit"
              >
                {t("send")}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
