import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}notification`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [token]);

  const handleDelete = async (notificationId) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      await axios.delete(`${API_URL}notification/${notificationId}`, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      setNotifications(
        notifications.filter((item) => item.notificationId !== notificationId)
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const isServiceExpired = (serviceEndDate) => {
    const today = new Date();

    return new Date(serviceEndDate) < today;
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">{t("Notifications")}</h2>
      <ul className="list-group">
        {notifications.length > 0 ? (
          notifications.map((item, index) => (
            <li key={index} className="list-group-item list-group-item-action">
              <div className="d-flex w-100 justify-content-between align-items-center">
                <Link
                  to={
                    t(item.message) === t("new_offer") &&
                    isServiceExpired(item.serviceEndDate)
                      ? `/app/user_offers/${item.serviceId}`
                      : t(item.message) === t("offer_updated") &&
                        isServiceExpired(item.serviceEndDate)
                      ? `/app/user_offers/${item.serviceId}`
                      : t(item.message) === t("offer_accepted")
                      ? `/app/pro_services/${item.serviceId}`
                      : t(item.message) === t("provider_canceled_service")
                      ? `/app/user_history_services/${item.serviceId}`
                      : t(item.message) === t("user_canceled_service")
                      ? `/app/pro_history_services/${item.serviceId}`
                      : t(item.message) === t("finish_service_user")
                      ? `/app/user_history_services/${item.serviceId}`
                      : t(item.message) === t("finish_service_provider")
                      ? `/app/pro_history_services/${item.serviceId}`
                      : "/app/notification"
                  }
                  className="flex-grow-1"
                >
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <h5 className="mb-1">{t(item.serviceName)}</h5>
                  </div>
                  <p className="mb-1">{t(item.message)}</p>
                  <small>{item.pet}</small>
                  <small className="text-muted d-block">
                    {new Date(item.createdAt).toLocaleString()}
                  </small>
                </Link>
                <button
                  onClick={() => {
                    handleDelete(item.notificationId);
                  }}
                  style={{ border: "none", background: "none" }}
                >
                  <FaTrash
                    className="text-danger"
                    style={{ fontSize: "1.5rem" }}
                  />
                </button>
              </div>
            </li>
          ))
        ) : (
          <li className="list-group-item text-center">
            <span>{t("no_notifications")}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Notification;
