import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import BreadCumb from "../../../components/Breadcrumb";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ServicesDetail = () => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getAdoptDetail = async () => {
      const response = await axios.get(
        `${API_URL}service/detail/${params.id}`,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setValues(response.data);
      }
    };
    getAdoptDetail();
  }, [params, token]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div>
      <BreadCumb path={t("Service_Detail")} />
      <div>
        {values && (
          <section className="breeder-details-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="breeder-details-content">
                    <h4 className="title">{values.pet.fullname}</h4>
                    <div className="breeder-details-img">
                      <img
                        src={values.pet.pet_image || "img/pet/default.png"}
                        alt=""
                      />
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("pet_Information")}</h5>
                      <div className="row">
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("gender")}:</h6>
                            <span>{t(values.pet.gender)}</span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("age")}:</h6>
                            <span>
                              {calculateAge(values.pet.birthday)} {t("years")}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("color")}:</h6>
                            <span>{values.pet.color}</span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("size")}:</h6>
                            <span>
                              {values.pet.size} {t("lbs")}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("city")}:</h6>
                            <span>
                              {values.user.city} {`-${values.user.district}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("breed")}:</h6>
                            <span>{values.pet.breed}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("other_Information")}</h5>
                      <div className="column">
                        <div>
                          <h5>{t("medicines")}</h5>
                          <p> {values.pet.medicine}</p>
                        </div>
                        <div>
                          <h5>{t("allergy")}</h5>
                          <p> {values.pet.allergy}</p>
                        </div>
                        <div>
                          <h5>{t("special_cases")}</h5>
                          <p> {values.pet.special_case}</p>
                        </div>
                      </div>
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("request_Information")}</h5>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("created_date")}:</h6>
                            <span>{formatDate(values.createdAt)}</span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("service_category")}:</h6>
                            <span>{t(values.job)}</span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("start_date")}:</h6>
                            <span>
                              {formatDate(values.dates.startDate)}{" "}
                              {formatTime(values.dates.startDate)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("end_date")}:</h6>
                            <span>
                              {formatDate(values.dates.endDate)}{" "}
                              {formatTime(values.dates.endDate)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {values.desc && (
                        <div className="breeder-dog-info">
                          <h5 className="title">{t("pet_owner_note")}</h5>
                          <p>{values?.desc}</p>
                        </div>
                      )}
                      {values.pet.desc && (
                        <div className="breeder-dog-info">
                          <h5 className="title">
                            {values.pet.fullname} {t(`about`)}
                          </h5>
                          <p>{values.pet.desc}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="breeder-sidebar">
                    {values.provider && (
                      <div>
                        <div className="widget breeder-widget">
                          <div className="breeder-widget-title d-flex justify-content-center flex-column align-items-center">
                            <img
                              src="/img/offer/processing.png"
                              alt="cancel"
                              width={100}
                            />
                            <Typography className="mt-3">
                              {t("service_process")}
                            </Typography>
                          </div>
                        </div>
                        <div className="widget breeder-widget p-3">
                          <h4
                            className="my-2"
                            style={{
                              borderBottom: "1px solid #ccc",
                              fontWeight: "700",
                              color: "#0a303a",
                              textAlign: "center",
                              paddingBottom: "10px",
                            }}
                          >
                            {t("provider")}
                          </h4>
                          <div
                            style={{ gap: 10 }}
                            className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center"
                          >
                            <Link to={`/app/provider/${values.provider._id}`}>
                              <img
                                src={
                                  values.provider?.image
                                    ? values.provider.image
                                    : "/img/unuser.png"
                                }
                                alt="unuser"
                                style={{ borderRadius: "8px" }}
                                width={100}
                              />
                            </Link>
                            <Link to={`/app/provider/${values.provider._id}`}>
                              <Typography>
                                {values.provider.fullname}
                              </Typography>
                            </Link>
                          </div>
                        </div>
                        {values?.offers[0]?.status && (
                          <div className="widget breeder-widget p-3 mb-4">
                            <div className="breeder-widget-title mb-20">
                              <h4
                                className="my-2"
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  fontWeight: "700",
                                  color: "#0a303a",
                                  textAlign: "center",
                                  paddingBottom: "10px",
                                }}
                              >
                                {t("provider_offer")}
                              </h4>
                              <div className="d-flex justify-content-start flex-column align-items-start">
                                <Typography>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("price")}:
                                  </span>{" "}
                                  {values?.offers[0]?.price} ₺{" "}
                                </Typography>
                                <Typography>
                                  <span style={{ fontWeight: "bold" }}>
                                    {t("message")}:
                                  </span>{" "}
                                  {values?.offers[0]?.comment}{" "}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="widget sidebar-newsletter">
                      <div className="sn-icon">
                        <img src="/img/icon/sn_icon.png" alt="" />
                      </div>
                      <div className="sn-title">
                        <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                        <p>{t("sign_up_for_latest_news")}</p>
                      </div>
                      <form className="sn-form">
                        <input
                          type="text"
                          placeholder={t("Enter_Your_Email")}
                        />
                        <button className="btn">{t("subscribe")}</button>
                      </form>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default ServicesDetail;
